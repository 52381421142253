html, body {
  width: 100%;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* The "#ddd" is not used at all, cause of 0px */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 0px #ddd inset !important;
  -webkit-box-shadow: 0 0 0 0px #ddd inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
